exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-hurricanes-and-decisions-js": () => import("./../../../src/pages/blog/hurricanes-and-decisions.js" /* webpackChunkName: "component---src-pages-blog-hurricanes-and-decisions-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-todo-js": () => import("./../../../src/pages/todo.js" /* webpackChunkName: "component---src-pages-todo-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-megatic-js": () => import("./../../../src/pages/work/megatic.js" /* webpackChunkName: "component---src-pages-work-megatic-js" */),
  "component---src-pages-work-resume-js": () => import("./../../../src/pages/work/resume.js" /* webpackChunkName: "component---src-pages-work-resume-js" */)
}

